<template>
  <div class="top-award-container">
    <div class="top-award-text">
      <span class="top-award-text-bold">Ист Клиник – снова лучший медцентр 2023</span>
      <span class="top-award-text-by">По версии 2 GIS</span>
      <span class="top-award-text-info">Эксперты Премии — все пользователи 2ГИС. Поэтому лучшими становятся компании, которые они любят, хвалят, советуют друзьям</span>
    </div>
    <div class="top-award-img-container">
      <div class="top-award-img"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.awards-container {
  display: flex;
  flex-direction: column;
  margin-top: 117px;
  margin-bottom: 50px;
}
.top-award-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 40px 40px 0px 0px;
  background-color: #F9F6F1;
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    border-radius: 30px;
  }
}
.top-award-text {
  display: flex;
  flex-direction: column;
  max-width: 451px;
  padding: 40px 0px 40px 40px;
  @media (max-width: 1023px) {
    padding: 0px 0px 24px 24px;
    min-width: 100%;
  }
}
.top-award-text-bold {
  margin-bottom: 12px;
  color: #262633;
  font-family: 'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.top-award-text-by {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.top-award-text-info {
  margin-top: 32px;
  max-width: 404px;
  color: #6A6358;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.048px;
  @media (max-width: 1023px) {
    max-width: 268px;
    margin-top: 24px;
  }
}
.top-award-img-container {
  position: relative;
  min-height: 260px;
  @media (max-width: 1023px) {
    min-height: 181px;
  }
}
.top-award-img {
  position: absolute;
  bottom: 0px;
  right: 59px;
  width: 469px;
  height: 330px;
  background: url(~/assets/img/main-page/top-award.webp) transparent 100% / contain no-repeat;
  @media (max-width: 1023px) {
    width: 333px;
    height: 234px;
    left: 50%;
    right: 0px;
    transform: translate(-50%, 0);
  }
}
</style>
